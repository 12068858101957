.tableMenu {
  &__icon {
    font-size: 20px;
    line-height: 8px;
    font-weight: 600;
    border: 1px solid $primary-color;
    // border-radius: 4px;
    padding: 0 5px;
    height: 20px;
    color: $primary-color;
    display: inline-flex;
    // align-items: center;
    border-radius: 10px;
    transform: rotate(90deg);
    cursor: pointer;
  }

  &__modal {
    width: 350px;
    background-color: #fff;
    padding: 20px;
    border-radius: 2px;

    & p {
      //   font-weight: 600;
      font-size: 15px;
    }

    &__btn {
      display: flex;
      justify-content: flex-end;
      gap: 20px;
      margin-top: 30px;
      flex-wrap: wrap;

      &__item {
        cursor: pointer;
        height: 35px;
        width: 100px;
        border: none;
        border-radius: 10px;
        background-color: rgb(231, 231, 231);

        &.delete {
          background-color: rgb(190, 1, 1);
          color: #fff;
        }
        &.app {
          background-color: $primary-color;
          color: #fff;
        }
      }
    }
  }
}
