.signin {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  // padding-top: 10%;
  background-color: $secondary-color;

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    & h2 {
      font-size: 30px;
      color: rgb(85, 85, 85);
    }
  }

  &__main {
    border: 1px solid #cfc8d8;
    padding: 50px 30px;
    margin: 2em 0;
    text-align: left;
    border-radius: 24px;
    font-size: 14px;
    background-color: #fff;

    & h2 {
      text-align: center;
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 20px;
    }

    &__inputCon {
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;

      & input {
        width: 350px;

        @media screen and (max-width: 450px) {
          width: 250px;
        }
      }
      & textarea {
        width: 100%;
      }

      &__pass {
        position: relative;
        & img {
          position: absolute;
          right: 20px;
          top: 50%;
          transform: translateY(-50%);
          cursor: pointer;
        }
      }
    }

    &__lost {
      display: inline-block;
      margin-bottom: 30px;
      color: #337ab7;
    }

    // & button {
    // }
  }
}
