:root {
  font-size: 62.5%;
  --body-padding: 13%;
}

@media screen and (max-width: 1257px) {
  :root {
    font-size: 62.5%;
    --body-padding: 10%;
  }
}

@media screen and (max-width: 1170px) {
  :root {
    font-size: 62.5%;
    --body-padding: 8%;
  }
}
@media screen and (max-width: 700) {
  :root {
    font-size: 62.5%;
    --body-padding: 5%;
  }
}

@import "abstracts/variable";

@import "layouts/dashboardLayout";

@import "components/sidebar";
@import "components/tableMenu";
@import "components/drawerDB";

@import "pages/notFound";
@import "pages/signin";
@import "pages/user";
@import "pages/defaulters";

*,
::before,
::after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-size: 1.6rem;
  font-family: "Space Grotesk", sans-serif;
  color: $text-color;
}

a {
  text-decoration: none;
  color: inherit;
}

img {
  max-width: 100%;
  height: auto;
}

ul {
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-family: "Space Grotesk", sans-serif;
}

.btn {
  background: $primary-color;
  min-width: 181px;
  height: 50px;
  border: none;
  font-size: 14px;
  border-radius: 12px;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  color: #f4f4f4;
  font-family: "Space Grotesk", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btnAlt {
  background: $primary-color;
  min-width: 200px;
  height: 50px;
  border: none;
  font-size: 13px;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  text-transform: uppercase;
  letter-spacing: 3.5px;
  color: #fff;
}

.btnAlt3 {
  background: $secondary-color;
  min-width: 220px;
  height: 50px;
  border: none;
  color: #fff;
  font-size: 13px;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  text-transform: uppercase;
  letter-spacing: 3.5px;

  // &:hover {
  //   background: $primary-colorAlt;
  // }
}

.loadingCon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
}

.logo {
  background-color: #fff;
}

// @szhsin/react-menu

// .szh-menu {
// box-shadow: none !important;
// background-color: #21282f !important;
// border-radius: 0 !important;
// color: #fff !important;
// font-size: 12px !important;
// text-transform: uppercase !important;
// width: 150px !important;

// & li {
// padding: 15px 5px !important;
//   font-weight: 500;

//   &:hover {
//     background-color: #21282f !important;
//     color: #fff !important;
//   }
// }
// }

/////////// MODAL STYLES ////////////

.modal-overlay {
  background: rgba(0, 0, 0, 0.3);
  z-index: 50;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: flex-end;
  height: 100%;
}

.modal-overlayAlt {
  background: rgba(0, 0, 0, 0.1);
  z-index: 50;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: flex-start;
  height: 100%;
}

.modal-overlayCenter {
  background: rgba(0, 0, 0, 0.5);
  z-index: 50;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.ReactModal__Content {
  &:focus-visible {
    outline: none !important;
  }
}

/////////// PAGINATE ////////////

.paginationBttns {
  padding: 0;
  /* height: 20px; */
  list-style: none;
  display: flex;
  // align-self: flex-end;
  justify-content: center;
  flex-wrap: wrap;
}

.paginationBttns a {
  height: 33px;
  width: 35px;
  margin: 0 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 14px;
  background-color: rgb(226, 226, 226);
  border-radius: 6px;
  cursor: pointer;
  transition: 0.3s;
}

.paginationBttns a:hover {
  color: white;
  background-color: $primary-color !important;
}

.paginationActive a {
  color: white;
  background-color: $primary-color !important;
}

.paginationDisabled a {
  color: #c3c3c3;
  background-color: transparent;

  &:hover {
    color: #c3c3c3;
    background-color: transparent !important;
  }
}
.pignateCon {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

/////////// DATE PICKER ////////////

.react-datepicker__calendar-icon {
  right: 0;
  top: 3px;
  fill: #aab8ca;
}

.react-datepicker-wrapper {
  display: block !important;
}
