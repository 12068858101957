.sidebar {
  width: 250px;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  padding-top: 0;
  background-color: #fff;

  @media screen and (max-width: 1000px) {
    display: none;

    &.draw {
      display: flex;
    }
  }

  &__logo {
    height: 80px;
    display: flex;
    align-items: center;
  }

  & h3 {
    font-size: 10px;
    letter-spacing: 0.1em;
    color: #8b8b8b;
    margin-top: 20px;
    margin-bottom: 20px;
    text-transform: uppercase;
    font-weight: 400;
  }

  &__list {
    padding: 0;
    margin: 0;

    &__item {
      padding: 15px 20px;
      cursor: pointer;
      transition: 0.3s;
      letter-spacing: 0.5px;
      border-radius: 6px;
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      gap: 8px;

      // &:hover {
      //   background-color: $primary-color;
      //   color: #fff;
      // }
      &.active {
        background-color: $primary-color;
        color: #fff;
      }
    }
  }

  &__btn {
    width: 150px;
    margin: 0 auto;
    margin-bottom: 20px;
    border: none;
    background-color: $primary-color;
    height: 30px;
    border-radius: 12px;
    color: #fff;
    cursor: pointer;
  }
}
