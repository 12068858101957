.dashboardLayout {
  &__topbar {
    width: calc(100vw - 251px);
    background-color: #fff;
    height: 80px;
    position: fixed;
    left: 251px;
    top: 0;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px;
    gap: 20px;

    @media screen and (max-width: 1000px) {
      width: 100vw;
      left: 0;
    }

    &__logoCon {
      & a {
        display: flex;
        align-items: center;
        gap: 10px;

        & h2 {
          font-size: 18px;
          position: relative;
          top: 5px;
        }
      }
    }
    &__logo {
      height: 20px;
    }

    &__logo {
      display: none;
      @media screen and (max-width: 1000px) {
        display: inline-block;
      }
    }

    &__search {
      position: relative;
      @media screen and (max-width: 1000px) {
        display: none;
      }
      & input {
        width: 400px;
        background: #fefefe;
        border: 1px solid #fafbff;
        height: 50px;
        border-radius: 12px;
        padding-left: 50px;
        padding-right: 20px;

        &::placeholder {
          font-family: "Space Grotesk", sans-serif;
          font-size: 14px;
          font-weight: 400;
        }

        &:focus {
          border: 1px solid #fafbff;
          outline: none;
        }
      }

      & img {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 20px;
      }
    }

    &__info {
      display: flex;
      align-items: center;
      gap: 20px;

      &__text {
        @media screen and (max-width: 500px) {
          display: none;
        }
      }

      & button {
        width: 80px;
        min-width: 120px;
        height: 35px;
      }
    }
  }

  &__main {
    padding-top: 110px;
    padding-left: 280px;
    padding-right: 30px;
    background-color: $secondary-color;
    min-height: 100vh;

    @media screen and (max-width: 1000px) {
      padding-left: 30px;
    }
  }
}
