.users {
  padding: 0 30px;

  @media screen and (max-width: 900px) {
    padding: 0;
  }
  &__title {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 20px;
  }

  &__table {
    overflow: auto;
    // padding: 30px;
    padding-top: 0px;

    &__body {
      display: flex;
      flex-direction: column;
      min-width: 900px;
    }

    &__row {
      padding: 20px 15px;
      display: flex;
      justify-content: space-between;
      gap: 10px;
      background: #fff;
      border-top: 1px solid #fafbff;
      &.head {
        border: none;
        border-bottom: 1px solid #00000024;
        background: #f7f8fb;
      }
      //   &:not(:last-child) {
      //     border-bottom: 1px solid rgb(184, 184, 184);
      //   }

      &__head {
        font-weight: 400;
        font-size: 12px;
        text-transform: uppercase;
        // color: $textAlt-color;
      }

      &__data {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #8b8b8b;

        & h4 {
          font-weight: 700;
        }

        &__btn {
          width: 100px;
          height: 30px;
          border: none;
          background-color: $primary-color;
          color: #fff;
          cursor: pointer;
          font-size: 13px;
          border-radius: 2px;
        }

        &__user {
          display: flex;
          flex-direction: column;
          //   gap: 2px;
          font-size: 13px;
          font-weight: 400;

          & p {
            color: #00101a;
          }
        }

        &__loan {
          padding: 5px 15px;
          background: #fff5ef;
          display: inline-block;
          border-radius: 75.19px;
          font-size: 11px;
          color: #f67e3e;

          &.active {
            color: #004d47;
            background: #fafbff;
          }
        }
      }
    }
  }
}
